import React, { useEffect } from 'react';

import { showFooterBranding } from './../../config';
import { PRIMARY_COLOR } from 'theme';
import { DEFAULT_LOGO } from './../../constants';
import Text from './../Text/Text';
import FooterBranding from './../UI/FooterBranding/FooterBranding';
import { isIos } from 'utils/helper';
import { IoCallSharp, IoChatboxEllipsesSharp } from 'react-icons/io5';

const ListItem = ({ link, text }) => {
  if (!link) {
    return null;
  }

  return (
    <a href={link} style={{ margin: 8 }} target="_blank">
      <Text size={16} weight={600}>
        {text}
      </Text>
    </a>
  );
};

const AppleBusinessChatForAndroid = ({
  show,
  iconColor,
  contactPhoneNumber,
  contactSMSNumber,
}) => {
  if (!show || !contactSMSNumber) return null;

  return (
    <div
      style={{
        height: 80,
        backgroundColor: 'white',
        padding: '10px 20px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Text weight="bold">Questions? We can help.</Text>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {/* <a
          style={{
            padding: '8px 10px',
            backgroundColor: 'rgb(239, 239, 244)',
            borderRadius: '50%',
            marginRight: 8,
          }}
          href={`tel:${contactNumber}`}
        >
          <IoCallSharp size="1.3rem" color={iconColor} />
        </a> */}
        <a
          style={{
            padding: '8px 10px',
            backgroundColor: 'rgb(239, 239, 244)',
            borderRadius: '50%',
          }}
          href={`sms:${contactSMSNumber}`}
        >
          <IoChatboxEllipsesSharp size="1.3rem" color={iconColor} />
        </a>
      </div>
    </div>
  );
};

const Footer = ({ organization, showSupportChat }) => {
  const organizationColor = organization?.theme?.colors?.primary
    ? organization?.theme?.colors?.primary
    : PRIMARY_COLOR;

  const showSupportSection = !!(
    organization?.links?.about ||
    organization?.links?.contact ||
    organization?.links?.support ||
    organization?.links?.privacy
  );

  useEffect(() => {
    if (showSupportChat && organization?.support?.chat?.type === 'intercom') {
      window.Intercom('boot', {
        app_id: organization?.support?.chat?.value,
      });
    }
    if (
      showSupportChat &&
      organization?.support?.chat?.type === 'appleBusinessChat'
    ) {
      if (isIos()) {
        // https://developer.apple.com/documentation/businesschat/adding_a_business_chat_button_to_your_website
        let placeholder = document.getElementById(
          'refreshAppleBusinessChatPlaceholder'
        );
        placeholder.classList.add('apple-business-chat-banner-container');
        placeholder.setAttribute(
          'data-apple-business-id',
          organization?.support?.chat?.value
        );
        placeholder.setAttribute(
          'data-apple-banner-cta',
          'Questions? We can help.'
        );
        placeholder.setAttribute(
          'data-apple-business-phone',
          organization?.support?.contact_phone_number
        );
        placeholder.setAttribute(
          'data-apple-banner-icon-color',
          organizationColor
        );
        window.appleBusinessChat.refresh();
      }
    }

    let link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement('link');
    link.rel = 'icon';
    link.href = organization?.logo
      ? organization?.logo
      : '%PUBLIC_URL%/edrv-icon.jpg';
    document.getElementsByTagName('head')[0].appendChild(link);

    let applefavIconLink =
      document.querySelector("link[rel*='apple-touch-icon']") ||
      document.createElement('link');

    applefavIconLink.rel = 'apple-touch-icon';
    applefavIconLink.href = organization?.logo
      ? organization?.logo
      : '%PUBLIC_URL%/edrv-icon.jpg';
    document.getElementsByTagName('head')[0].appendChild(applefavIconLink);

    let title =
      document.querySelector('title') || document.createElement('title');
    title.innerHTML = organization?.name
      ? organization?.name
      : 'eDRV EV Charging';
    document.getElementsByTagName('head')[0].appendChild(title);
  }, []);

  return (
    <>
      <div className="footer" style={{ paddingBottom: 0 }}>
        {showSupportSection ? (
          <>
            <img className="logo" src={organization?.logo ?? DEFAULT_LOGO} />
            <br />
            <ListItem link={organization?.links?.about} text="About" />
            <ListItem link={organization?.links?.contact} text="Contact" />
            <ListItem link={organization?.links?.support} text="Support" />
            <ListItem link={organization?.links?.privacy} text="Privacy" />
          </>
        ) : null}
        <AppleBusinessChatForAndroid
          show={
            showSupportChat &&
            organization?.support?.chat?.type === 'appleBusinessChat' &&
            !isIos()
          }
          iconColor={organizationColor}
          contactPhoneNumber={organization?.support?.contact_phone_number}
          contactSMSNumber={organization?.support?.contact_sms_number}
        />
      </div>
      <FooterBranding color={PRIMARY_COLOR} show={showFooterBranding()} />
    </>
  );
};

export default Footer;
