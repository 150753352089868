import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import GoogleMapReact from 'google-map-react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import qs from 'qs';

import {
  Footer,
  DetectSession,
  Header,
  Text,
  Loading,
  ChargestationMarker,
  LocateIcon,
  FlashIcon,
  LocationIcon,
  Notification,
  DetectLowBalance,
} from 'components';
import {
  updateLocationInCookie,
  updateCookie,
  ToastZoomTransition,
} from 'utils/helper';
import { DEFAULT_LOCATION } from './../../constants';
import { DEFAULT_GREY, PRIMARY_COLOR } from 'theme';

//TODO abstract the maps component
function Home({ organization, user, auth0User }) {
  const dispatch = useDispatch();
  const organizationColor = organization?.theme?.colors?.primary
    ? organization?.theme?.colors?.primary
    : PRIMARY_COLOR;
  const history = useHistory();
  const [cookies, setCookies] = useCookies();
  const [currentLocation, setCurrentLocation] = useState(
    organization?.driver_application?.default_location?.coordinates
      ? {
          lat:
            organization?.driver_application?.default_location
              ?.coordinates[1] ?? DEFAULT_LOCATION.lat,
          lng:
            organization?.driver_application?.default_location
              ?.coordinates[0] ?? DEFAULT_LOCATION.lng,
        }
      : DEFAULT_LOCATION
  );
  const [chargeStations, setChargeStations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [address, setAddress] = useState('');
  const [auth0ErrorTitle, setAuth0ErrorTitle] = useState('');
  const [auth0ErrorDescription, setAuth0ErrorDescription] = useState('');
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    const raw_query = window.location.search.substring(1);
    const query = qs.parse(raw_query);

    if (query.error_description) {
      setAuth0ErrorTitle(`${query.error}`);
      setAuth0ErrorDescription(`${query.error_description}`);
      setShowNotification(true);
    }

    init();
    updateLocationInCookie('/', cookies, setCookies);
  }, []);

  const init = async () => {
    const redirectAfterAuth0 = localStorage.getItem('redirectAfterAuth0');

    if (redirectAfterAuth0) {
      const options = { path: '/' };
      setCookies('store', {}, options);

      history.replace(redirectAfterAuth0);
      localStorage.removeItem('redirectAfterAuth0');
    }

    const request = await window.fetch(
      `${process.env.REACT_APP_API_URL}/v1/public/chargestations?paginate_limit=1000`
    );
    if (request.status == 200) {
      const response = await request.json();
      const locations = response.result.filter((eachChagestation) => {
        return (
          eachChagestation.location.coordinates.latitude >= -90 &&
          eachChagestation.location.coordinates.latitude <= 90 &&
          eachChagestation.location.coordinates.longitude >= -180 &&
          eachChagestation.location.coordinates.longitude <= 180
        );
      });
      // setCurrentLocation({
      //   lat:
      //     locations[0]?.location?.coordinates?.latitude ?? DEFAULT_LOCATION.lat,
      //   lng:
      //     locations[0]?.location?.coordinates?.longitude ??
      //     DEFAULT_LOCATION.lng,
      // });
      setChargeStations(locations);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const MarkerCard = () => (
    <CustomCard style={{}}>
      <Text
        size={26}
        weight={700}
        style={{ padding: '0 16px', textAlign: 'center' }}
      >
        Map of Chargestations
      </Text>
      <div style={{ margin: 4 }}>
        <button
          style={{
            backgroundColor: organizationColor,
            borderWidth: 0,
            borderRadius: 6,
            width: '100%',
            padding: 16,
          }}
          onClick={() => {
            history.push('/maps');
          }}
        >
          <Text
            size={16}
            weight={700}
            color="white"
            style={{ letterSpacing: '0.07em' }}
          >
            Find a charge station
          </Text>
        </button>
      </div>
    </CustomCard>
  );

  const handleChange = (_address) => {
    setAddress(_address);
  };

  const handleSelect = (_address) => {
    geocodeByAddress(_address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        history.push(`/maps?search=${encodeURIComponent(_address)}`, {
          latLng,
        });
      })
      .catch((error) => console.error('Error', error));
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        hideProgressBar
        autoClose={2500}
        transition={ToastZoomTransition()}
        closeButton={false}
      />
      <DetectSession
        cookies={cookies}
        setCookies={setCookies}
        updateCookie={updateCookie}
      />
      <DetectLowBalance user={user} organization={organization} />
      <Notification
        title={auth0ErrorTitle}
        message={auth0ErrorDescription}
        isShowing={showNotification}
        timeoutSeconds={7}
        hide={() => {
          setShowNotification(false);
        }}
        organizationColor="#fff7fa"
        style={{
          border: '2px solid rgb(250, 192, 189)',
        }}
        textColor="black"
      />

      <Header organization={organization} user={user} auth0User={auth0User} />
      <br />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{ display: 'flex', flexDirection: 'column', height: '87vh' }}
        >
          <div style={{ height: 132 }} style={{ margin: '0 16px' }}>
            <Text size={36} weight={900} style={{ lineHeight: 1.3 }}>
              On Demand Electric Vehicle Charging
            </Text>
          </div>
          <br />
          <Text
            size={16}
            weight={400}
            style={{ margin: '0 20px', paddingBottom: 8 }}
          >
            Locate
            <Text color={organizationColor} weight={600} size={18}>
              {' '}
              {organization?.name}{' '}
            </Text>
            charge stations.
          </Text>

          <div
            style={{
              borderWidth: 0,
              borderRadius: 6,
              padding: '4px 16px',
              margin: '8px 0px',
              maxWidth: 500,
            }}
          >
            <PlacesAutocomplete
              value={address}
              onChange={handleChange}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      height: 48,
                      alignItems: 'center',
                      backgroundColor: '#E4E4E4',
                    }}
                  >
                    <input
                      id="search"
                      {...getInputProps({
                        placeholder: 'Chargestation ID or address',
                        className: 'location-search-input',
                        style: {
                          height: 48,
                          backgroundColor: '#E4E4E4',
                          borderWidth: 0,
                          borderRadius: 6,
                          padding: '0 16px',
                          width: '100%',
                          maxWidth: 500,
                        },
                      })}
                    />
                    <LocateIcon
                      height={24}
                      width={24}
                      pathColor="#98A1A9"
                      style={{ marginRight: 12 }}
                      onClick={() => {
                        navigator.geolocation.getCurrentPosition(
                          function (position) {
                            history.push('/maps?search=', {
                              latLng: {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                              },
                            });
                          },
                          function (error) {
                            toast.info('Location permission denied');
                          }
                        );

                        try {
                          navigator.permissions
                            .query({ name: 'geolocation' })
                            .then(function (result) {
                              if (
                                result.state === 'granted' ||
                                result.state === 'prompt'
                              ) {
                              } else {
                                toast.info('Location permission denied');
                              }
                            });
                        } catch (e) {
                          console.log(e);
                        }
                      }}
                    />
                  </div>
                  <div
                    className="autocomplete-dropdown-container"
                    style={{
                      zIndex: 2000,
                      width: '91%',
                      maxWidth: 500,
                      position: 'absolute',
                      marginLeft: 1,
                    }}
                  >
                    {loading && <div>Loading...</div>}
                    {!loading &&
                      address.length > 0 &&
                      chargeStations
                        .filter((eachChagestation) =>
                          eachChagestation.static_endpoint
                            .toLowerCase()
                            .includes(address.toLowerCase())
                        )
                        .map((eachChargestation) => (
                          <div
                            style={{
                              backgroundColor: '#ffffff',
                              cursor: 'pointer',
                              padding: 8,
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                            onClick={(e) => {
                              history.push(
                                `/${eachChargestation.static_endpoint}`
                              );
                              e.stopPropagation();
                            }}
                          >
                            <div
                              style={{
                                width: 20,
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <FlashIcon
                                height={12}
                                width={12}
                                pathColor={DEFAULT_GREY}
                              />
                            </div>
                            <div style={{ flex: 1, overflow: 'hidden' }}>
                              <Text
                                style={{
                                  marginLeft: 8,
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                                size={16}
                              >
                                {eachChargestation.static_endpoint} -{' '}
                                {
                                  eachChargestation.location.address
                                    .streetAndNumber
                                }
                              </Text>
                            </div>
                          </div>
                        ))}
                    {!loading &&
                      suggestions.map((suggestion, index) => {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item';
                        return (
                          <>
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style: {
                                  backgroundColor: '#ffffff',
                                  cursor: 'pointer',
                                  padding: 8,
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                },
                              })}
                            >
                              <div
                                style={{
                                  width: 20,
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <LocationIcon
                                  height={12}
                                  width={12}
                                  pathColor={DEFAULT_GREY}
                                />
                              </div>

                              <div style={{ flex: 1, overflow: 'hidden' }}>
                                <Text
                                  style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  }}
                                  size={16}
                                >
                                  {suggestion.description}
                                </Text>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </>
              )}
            </PlacesAutocomplete>
          </div>
          <br />
          <div style={{ height: '100vh', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
              }}
              center={currentLocation}
              zoom={10}
              options={{
                zoomControl: false,
                fullscreenControl: false,
                rotateControl: false,
              }}
            >
              {chargeStations.map((eachChargestation) => (
                <ChargestationMarker
                  lat={eachChargestation.location.coordinates.latitude}
                  lng={eachChargestation.location.coordinates.longitude}
                  chargestation={eachChargestation}
                  organization={organization}
                />
              ))}
            </GoogleMapReact>
          </div>
        </div>
        <MarkerCard />
        <div>
          <Footer organization={organization} />
        </div>
      </div>
    </React.Fragment>
  );
}

const CustomCard = styled.div`
  display: flex;
  position: absolute;
  bottom: 40px;
  width: 80%;
  margin-left: 10%;
  flex-direction: column;
  background-color: white;
  padding: 32px 16px;
  padding-bottom: 16px;
  justify-content: space-between;
  height: 210px;
  &:after {
    content: '';
    position: absolute;
    margin-top: -6px;
    margin-left: -5px;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-bottom: 30px solid white;
    border-radius: 8px;
    transform: rotate(-180deg);
    bottom: -27px;
    left: 135px;
  }
`;

const mapStateToProps = (state) => ({
  organization: state.organization,
  user: state.user,
  auth0User: state.auth0User,
});

export default connect(mapStateToProps)(Home);
