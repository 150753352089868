import { stringToBoolean } from './../utils/helper';

const showFooterBranding = () => {
  return stringToBoolean(process.env?.REACT_APP_SHOW_FOOTER_BRANDING ?? true);
};

const disableAccounts = () => {
  return stringToBoolean(process.env?.REACT_APP_DISABLE_ACCOUNTS ?? true);
};

const disableWallets = () => {
  return stringToBoolean(process.env?.REACT_APP_DISABLE_WALLETS ?? true);
};

const isDevelopment = () => {
  let flag = process.env?.REACT_APP_API_URL === 'https://api.edrv.io';

  return !flag;
};

const isTestMode = ({ test_mode }) => {
  return test_mode;
};

export {
  showFooterBranding,
  disableAccounts,
  disableWallets,
  isDevelopment,
  isTestMode,
};
