import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import qs from 'qs';
import { Button } from 'reactstrap';
import { useCookies } from 'react-cookie';
import { IoMailOutline, IoCallOutline, IoLocationSharp } from 'react-icons/io5';
import { useAuth0 } from '@auth0/auth0-react';

import {
  MdBatteryCharging20,
  MdBatteryCharging50,
  MdBatteryCharging80,
  MdBatteryChargingFull,
} from 'react-icons/md';
import {
  Footer,
  Loading,
  Text,
  KeyboardArrowDownIcon,
  KeyboardArrowUpIcon,
  Header,
  BxTimeFiveIcon,
  FlashIcon,
  Notification,
  LogoutButton,
  SigninButton,
} from './../../components';
import { CONNECTOR_STATUS, TRANSACTION_STATUS } from './../../constants';
import './Session.css';
import { PRIMARY_COLOR } from 'theme';
import ConnectorAvailable from './../../assets/img/connector_available.png';
import ConnectorPluggedIn from './../../assets/img/connector_plugged_in.png';
import ConnectorSuspendedEV from './../../assets/img/connector_suspendedEV.png';
import ConnectorTimeout from './../../assets/img/connector_timeout.png';
import Information from './../Chargestation/Information';
import {
  hhmmss,
  convertCurrencyFromText,
  updateCookie,
  generateCode,
} from './../../utils/helper';
import organization from 'reducers/organization';
import api from 'utils/api';
import { disableAccounts } from './../../config';

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
}

const renderBatteryIcon = (value) => {
  if (value === 3) {
    return <MdBatteryChargingFull />;
  } else if (value === 2) {
    return <MdBatteryCharging80 />;
  } else if (value === 1) {
    return <MdBatteryCharging50 />;
  } else {
    return <MdBatteryCharging20 />;
  }
};

const createRenderDataObject = (
  title,
  subTitle,
  image,
  imageAlt,
  startCharginButton,
  stopCharginButton,
  chargingTime,
  energyDelivered
) => {
  return {
    title,
    subTitle,
    image,
    imageAlt,
    startCharginButton,
    stopCharginButton,
    chargingTime,
    energyDelivered,
  };
};

//Refector to seperate components for each state
const RENDER_DATA_CONNECTION_LOST = createRenderDataObject(
  'Connection lost temporarily',
  'Please try refreshing the screen',
  ConnectorTimeout,
  '',
  false,
  false,
  '',
  ''
);

const RENDER_DATA_PAYMENT_INTENT_FAIL = createRenderDataObject(
  'Unable to cancel pre-auth payment',
  'Transaction start has been registered',
  ConnectorTimeout,
  '',
  true,
  false,
  '',
  ''
);

const RENDER_DATA_PUBLIC_START_FAIL = createRenderDataObject(
  'Unable to start a charging transaction',
  'Please try again shortly',
  ConnectorTimeout,
  '',
  true,
  false,
  '',
  ''
);

const RENDER_DATA_PUBLIC_STOP_FAIL = createRenderDataObject(
  'Unable to stop transaction remotely',
  'You can try again in a few minutes or unplug your cable to stop',
  ConnectorTimeout,
  '',
  true,
  false,
  '',
  ''
);

const setRenderDataFromConnectorStatus = (
  currentState,
  previousState,
  renderData,
  setRenderData,
  transaction,
  organization,
  stopCommnadSuccess
) => {
  if (
    transaction &&
    transaction.status &&
    transaction.status === TRANSACTION_STATUS.ENDED
  ) {
    setRenderData(
      createRenderDataObject(
        'Finished Charging',
        `Thank you for charging with ${organization?.name}. A detailed receipt has been emailed to you. (Please check your spam folders)`,
        '',
        convertCurrencyFromText(transaction?.cost?.currency) +
          ' ' +
          parseFloat(transaction?.cost?.amount ?? 0).toFixed(2),
        false,
        false,
        `${hhmmss(transaction?.metrics?.timeSpentCharging ?? 0)}`,
        `${parseFloat(
          (transaction?.metrics?.wattHoursConsumed ?? 0) / 1000
        ).toFixed(2)}kWh`
      )
    );
  } else if (
    currentState === CONNECTOR_STATUS.AVAILABLE &&
    !stopCommnadSuccess
  ) {
    setRenderData(
      createRenderDataObject(
        'Start Charging',
        'Please plug-in cable to your car to start charging.',
        ConnectorAvailable,
        '',
        true,
        false,
        '',
        ''
      )
    );
  } else if (
    currentState === CONNECTOR_STATUS.PREPARING &&
    !stopCommnadSuccess
  ) {
    setRenderData(
      createRenderDataObject(
        'Start Charging',
        'Thanks for plugging in. Please click the Start Charging button.',
        ConnectorPluggedIn,
        '',
        true,
        false,
        '',
        ''
      )
    );
  } else if (
    currentState === CONNECTOR_STATUS.SUSPENDEDEV &&
    !stopCommnadSuccess
  ) {
    setRenderData(
      createRenderDataObject(
        'EV Charged',
        'Please disconnect cable or click Stop Charging below',
        '',
        (transaction?.cost_estimate?.currency
          ? convertCurrencyFromText(transaction?.cost_estimate?.currency)
          : '') +
          ' ' +
          parseFloat(transaction?.cost_estimate?.amount ?? 0).toFixed(2),
        false,
        true,
        `${hhmmss(transaction?.metrics?.timeSpentCharging ?? 0)}`,
        `${parseFloat(
          (transaction?.metrics?.wattHoursConsumed ?? 0) / 1000
        ).toFixed(2)}kWh`
      )
    );
  } else if (
    currentState === CONNECTOR_STATUS.CHARGING &&
    !stopCommnadSuccess
  ) {
    setRenderData(
      createRenderDataObject(
        'Charging',
        '',
        '',
        (transaction?.cost_estimate?.currency
          ? convertCurrencyFromText(transaction?.cost_estimate?.currency)
          : '') +
          ' ' +
          parseFloat(transaction?.cost_estimate?.amount ?? 0).toFixed(2),
        false,
        true,
        `${hhmmss(transaction?.metrics?.timeSpentCharging ?? 0)}`,
        `${parseFloat(
          (transaction?.metrics?.wattHoursConsumed ?? 0) / 1000
        ).toFixed(2)}kWh`
      )
    );
  } else if (
    (previousState === CONNECTOR_STATUS.CHARGING &&
      (currentState === CONNECTOR_STATUS.AVAILABLE ||
        currentState === CONNECTOR_STATUS.FINISHING ||
        currentState === CONNECTOR_STATUS.PREPARING)) ||
    stopCommnadSuccess
  ) {
    setRenderData(
      createRenderDataObject(
        'Session Finishing',
        'Awaiting final transaction readings from the charge station',
        '',
        convertCurrencyFromText(transaction?.cost_estimate?.currency) +
          ' ' +
          parseFloat(transaction?.cost_estimate?.amount ?? 0).toFixed(2),
        false,
        false,
        `${hhmmss(transaction?.metrics?.timeSpentCharging ?? 0)}`,
        `${parseFloat(
          (transaction?.metrics?.wattHoursConsumed ?? 0) / 1000
        ).toFixed(2)}kWh`
      )
    );
  } else if (
    (previousState === CONNECTOR_STATUS.SUSPENDEDEV &&
      (currentState === CONNECTOR_STATUS.AVAILABLE ||
        currentState === CONNECTOR_STATUS.UNAVAILABLE)) ||
    stopCommnadSuccess
  ) {
    setRenderData(
      createRenderDataObject(
        'Session Finishing',
        'Awaiting final transaction readings from the charge station',
        '',
        convertCurrencyFromText(transaction?.cost_estimate?.currency) +
          ' ' +
          parseFloat(transaction?.cost_estimate?.amount ?? 0).toFixed(2),
        false,
        false,
        `${hhmmss(transaction?.metrics?.timeSpentCharging ?? 0)}`,
        `${parseFloat(
          (transaction?.metrics?.wattHoursConsumed ?? 0) / 1000
        ).toFixed(2)}kWh`
      )
    );
  }
};

//TODO make consistent height when changing state
function Session(props) {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  const location = useLocation();
  const [cookies, setCookies] = useCookies();
  const [maxHeight, setMaxHeight] = useState(72);
  const [paymentIntent, setPaymentIntent] = useState({});
  const { paymentIntentId } = useParams();
  const [renderData, setRenderData] = useState(
    createRenderDataObject(
      'Start Charging',
      'Please plug-in cable to your car to start charging',
      ConnectorAvailable,
      '',
      true,
      false,
      '',
      ''
    )
  );

  const [connectorId, setConnectorId] = useState('');
  const [connector, setConnector] = useState({});
  const [connectorStatus, setConnectorStatus] = useState(
    CONNECTOR_STATUS.AVAILABLE
  );
  const [previousConnectorStatus, setPreviousConnectorStatus] = useState(
    CONNECTOR_STATUS.AVAILABLE
  );
  const [startCommandLoading, setStartCommandLoading] = useState(false);
  const [stopCommandLoading, setStopCommandLoading] = useState(false);
  const [stopCommandSuccess, setStopCommandSuccess] = useState(false);
  const [cancelCommandLoading, setCancelCommandLoading] = useState(false);
  const [chargestation, setChargestation] = useState({});
  const [transaction, setTransaction] = useState({});
  const [showNotification, setShowNotification] = useState(
    location?.state?.from === 'payment' ||
      location?.state?.from === 'chargestation'
  );
  const organizationColor = props?.organization?.theme?.colors?.primary;
  const [blinkCount, setBlinkCount] = useState(0);
  const [showCancelButton, setShowCancelButton] = useState(false);

  useEffect(() => {
    // updateLocationInCookie(`connector/${endpoint}`, cookies, setCookies);
    fetchStatus();
  }, []);

  useInterval(() => {
    setBlinkCount(blinkCount + 1 > 3 ? 0 : blinkCount + 1);
  }, 1000);

  const fetchStatus = async () => {
    if (!paymentIntentId || !connectorId) return;

    //TODO query the transactions endpoint, filter by user and sort desc by createdAt with limit 1
    try {
      const response = await window.fetch(
        `${process.env.REACT_APP_API_URL}/v1/public/status`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            intent_id: paymentIntentId,
            connector_id: connectorId,
          }),
        }
      );

      if (response.status === 200) {
        const result = await response.json();
        const status = result.result;

        setTransaction({
          ...status.transaction,
          cost_estimate: status.cost_estimate,
        });
        updateCookie(
          {
            connectorId,
            paymentIntentId,
            transactionId: status?.transaction?._id,
          },
          cookies,
          setCookies
        );
        setConnector({ ...status.connector });
        setPaymentIntent(status.intent);
        setChargestation(status.chargestation);
        setShowCancelButton(!status.isTokenExists);
      } else {
        setRenderData(RENDER_DATA_CONNECTION_LOST);
      }
    } catch (e) {
      setRenderData(RENDER_DATA_CONNECTION_LOST);
    }
  };

  useEffect(() => {
    if (connector && connector.status) {
      // Ignore the Unavailable status for the previous connector status
      if (connectorStatus !== CONNECTOR_STATUS.UNAVAILABLE) {
        setPreviousConnectorStatus(connectorStatus);
      }

      setConnectorStatus(connector.status);
      setRenderDataFromConnectorStatus(
        connector.status,
        previousConnectorStatus,
        renderData,
        setRenderData,
        transaction,
        props.organization,
        stopCommandSuccess
      );
    }
  }, [connector, connectorStatus, previousConnectorStatus]);

  useEffect(() => {
    const raw_query = window.location.search.substring(1);
    const query = qs.parse(raw_query);

    if (query.c_id) {
      setConnectorId(query.c_id);
      // updateCookie({ session_id: query.session_id });
    }
    // updateCookie({ session_id: query.session_id });
  }, []);

  useEffect(() => {
    fetchStatus();

    const refreshInterval = setInterval(() => {
      fetchStatus();
    }, 10000);

    return () => {
      clearInterval(refreshInterval);
    };
  }, [paymentIntentId, connectorId]);

  const handlecancel = async (event) => {
    event.preventDefault();
    setCancelCommandLoading(true);

    try {
      const response = await window.fetch(
        `${process.env.REACT_APP_API_URL}/v1/public/intent/cancel`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            intent_id: paymentIntentId,
            stripe_account: props.organization.stripe_connected_account_id,
            connector_id: connectorId,
          }),
        }
      );

      if (response.status !== 200) {
        setRenderData(RENDER_DATA_PAYMENT_INTENT_FAIL);
      }
    } catch (e) {
      setRenderData(RENDER_DATA_PAYMENT_INTENT_FAIL);
    }
  };

  const handleStart = async (event) => {
    event.preventDefault();
    setStartCommandLoading(true);

    try {
      if (isAuthenticated) {
        let token = await getAccessTokenSilently();
        const tokenResponse = await api.post(token, `v1/tokens`, {
          active: true,
          physicalId: generateCode(10),
          type: 'Central',
          user: props?.user?._id,
          channel: 'stripe',
          channel_id: paymentIntentId,
        });

        token = await getAccessTokenSilently();
        const remoteStartResponse = await api.post(
          token,
          `v1/commands/remotestart`,
          {
            chargestation: chargestation._id,
            user: props?.user?._id,
            token: tokenResponse.result._id,
            connector: connector._id,
          }
        );

        setTimeout(fetchStatus, 2000);
      } else {
        const response = await window.fetch(
          `${process.env.REACT_APP_API_URL}/v1/public/start`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              intent_id: paymentIntentId,
              connector_id: connectorId,
            }),
          }
        );

        if (response.status === 200) {
          setTimeout(fetchStatus, 2000);
        } else {
          setRenderData(RENDER_DATA_PUBLIC_START_FAIL);
        }
      }
    } catch (e) {
      setRenderData(RENDER_DATA_PUBLIC_START_FAIL);
      setStartCommandLoading(false);
    }
  };

  const handleStop = async (event) => {
    event.preventDefault();
    setStopCommandLoading(true);

    try {
      if (isAuthenticated) {
        const token = await getAccessTokenSilently();
        const remoteStopResponse = await api.post(
          token,
          `v1/commands/remotestop`,
          {
            chargestation: chargestation._id,
            user: props?.user?._id,
            transaction: transaction._id,
          }
        );

        setStopCommandSuccess(true);
        setTimeout(fetchStatus, 2000);
      } else {
        const response = await window.fetch(
          `${process.env.REACT_APP_API_URL}/v1/public/stop`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              intent_id: paymentIntentId,
              connector_id: connectorId,
            }),
          }
        );
        if (response.status === 200) {
          setTimeout(fetchStatus, 2000);
          setStopCommandSuccess(true);
        } else {
          setRenderData(RENDER_DATA_PUBLIC_STOP_FAIL);
        }
      }
    } catch (e) {
      setRenderData(RENDER_DATA_PUBLIC_STOP_FAIL);
      setStopCommandLoading(false);
    }
  };

  const cancelButton = (
    <div style={{ padding: 16 }}>
      <Button
        id="cancel-charging"
        className="action-button"
        style={{
          color: 'black',
          backgroundColor: '#DDD',
          borderColor: '#DDD',
          opacity: startCommandLoading || cancelCommandLoading ? 0.4 : 1,
        }}
        onClick={handlecancel}
        disabled={startCommandLoading || cancelCommandLoading}
      >
        {cancelCommandLoading ? 'Cancelling Pre-Auth Payment' : 'Cancel'}
      </Button>
    </div>
  );

  const startButton = (
    <div style={{ padding: 16 }}>
      <Button
        id="start-charging"
        className="action-button"
        style={{
          color: 'white',
          backgroundColor: organizationColor
            ? organizationColor
            : PRIMARY_COLOR,
          borderColor: organizationColor ? organizationColor : PRIMARY_COLOR,
          opacity:
            startCommandLoading || cancelCommandLoading || !showCancelButton
              ? 0.4
              : 1,
        }}
        onClick={handleStart}
        disabled={
          startCommandLoading || cancelCommandLoading || !showCancelButton
        }
      >
        {startCommandLoading || !showCancelButton
          ? 'Connecting...'
          : 'Start Charging'}
      </Button>
    </div>
  );

  const stopButton = (
    <div style={{ padding: 16 }}>
      <Button
        id="stop-charging"
        className="action-button"
        style={{
          color: 'white',
          backgroundColor: organizationColor
            ? organizationColor
            : PRIMARY_COLOR,
          borderColor: organizationColor ? organizationColor : PRIMARY_COLOR,
          opacity: stopCommandLoading ? 0.4 : 1,
        }}
        onClick={handleStop}
        disabled={stopCommandLoading}
      >
        {stopCommandLoading ? 'Stopping Session' : 'Stop Charging'}
      </Button>
    </div>
  );

  const renderCard = (d) => {
    return (
      <div
        className="card-container"
        style={{
          backgroundColor: 'white',
        }}
        id={d.id}
      >
        <div style={{ paddingRight: 8 }}>
          {d.title === 'Charging time' ? (
            <BxTimeFiveIcon
              height="20"
              width="20"
              pathColor={organizationColor ? organizationColor : PRIMARY_COLOR}
            />
          ) : (
            <FlashIcon
              height="18"
              width="18"
              pathColor={organizationColor ? organizationColor : PRIMARY_COLOR}
            />
          )}
        </div>
        <div style={{ flex: 1 }}>
          <Text size={14} weight={400}>
            {d.title}
          </Text>
        </div>
        {d.title === 'Charging time' ? (
          <div>
            <Text size={16} weight={600}>
              {d.detail}
            </Text>
          </div>
        ) : (
          <div>
            <Text size={16} weight={600}>
              {d.detail}
            </Text>
          </div>
        )}
      </div>
    );
  };

  const renderSession = () => {
    if (connector && paymentIntent) {
      return (
        <div style={{ backgroundColor: '#F6F6F6' }}>
          <Notification
            message="Your pre-auth payment has been processed. An email receipt has been sent to you. (Please check your spam folders)"
            isShowing={showNotification}
            timeoutSeconds={3}
            hide={() => {
              setShowNotification(false);
            }}
            organizationColor={organizationColor}
          />
          <Header
            organization={props.organization}
            user={props.user}
            auth0User={props.auth0User}
            backgroundColor="rgb(246, 246, 246)"
          />

          <div
            style={{
              minHeight: 100,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0 40px',
            }}
          >
            <Text weight={600} size={20} id="title">
              {renderData.title}
            </Text>
            <Text weight={600} size={30}>
              {renderData.title === 'Charging'
                ? renderBatteryIcon(blinkCount)
                : null}
            </Text>
            <br />
            <Text
              weight={400}
              size={14}
              color="#98A1A9"
              style={{ textAlign: 'center' }}
              id="sub-title"
            >
              {renderData.subTitle}
            </Text>
          </div>
          <div
            style={{
              minHeight: 300,
              display: 'flex',
              justifyContent: renderData.image ? 'center' : 'space-between',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            {renderData.image ? (
              <img src={renderData.image} />
            ) : (
              <Text weight={600} size={56}>
                {renderData.imageAlt}
              </Text>
            )}

            {!renderData.image ? (
              <div style={{ width: '100%' }}>
                {renderCard({
                  icon: null,
                  title: 'Charging time',
                  detail: renderData.chargingTime,
                  id: 'charging-time-container',
                })}
                {renderCard({
                  icon: null,
                  title: 'Energy Delivered',
                  detail: renderData.energyDelivered,
                  id: 'energy-delivered-container',
                })}
              </div>
            ) : null}
          </div>

          {renderData.startCharginButton ? startButton : null}
          {renderData.stopCharginButton ? stopButton : null}
          {renderData.startCharginButton &&
          Object.keys(transaction).length < 2 &&
          showCancelButton
            ? cancelButton
            : null}
          {(props?.organization?.support?.email ||
            props?.organization?.support?.email) && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'white',
                padding: 8,
                paddingTop: 20,
              }}
            >
              {props?.organization?.support?.email && (
                <Information
                  Icon={IoMailOutline}
                  type="Support Email"
                  value={props?.organization?.support?.email}
                  href={`mailto:${props?.organization?.support?.email}`}
                  color={organizationColor}
                />
              )}
              {props?.organization?.support?.contact_phone_number && (
                <Information
                  Icon={IoCallOutline}
                  type="Support Phone"
                  value={props?.organization?.support?.contact_phone_number}
                  href={`tel:${props?.organization?.support?.contact_phone_number}`}
                  color={organizationColor}
                />
              )}
              {chargestation?.location?.address && (
                <Information
                  Icon={IoLocationSharp}
                  type="Location"
                  value={`${
                    chargestation?.location?.address?.streetAndNumber ?? ''
                  } ${chargestation?.location?.address?.city ?? ''}
              ${chargestation?.location?.address?.postalCode ?? ''}
              ${chargestation?.location?.address?.state ?? ''}`}
                />
              )}
            </div>
          )}

          {renderData.title === 'Charging' ||
          renderData.title === 'Finished Charging' ||
          renderData.title === 'Session Finishing' ? null : (
            <div
              className="session-information"
              style={{ maxHeight }}
              onClick={() => {
                maxHeight == 72 ? setMaxHeight(200) : setMaxHeight(72);
              }}
            >
              <div style={{ flex: 9, margin: '14px 0' }}>
                <Text weight={400} size={12}>
                  If you are unable to start a charging session within 5 minutes
                  of pressing the start charging button, your pre-pay amount
                  will be refunded to you and you will have to pre-authorize
                  again. For security reasons, stop charging is only permitted
                  from the same mobile device that started the transaction. You
                  can always stop charging from your vehicle and drive away.
                </Text>
              </div>
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {maxHeight == 72 ? (
                  <KeyboardArrowDownIcon
                    height={12}
                    width={12}
                    pathColor="#98A1A9"
                  />
                ) : (
                  <KeyboardArrowUpIcon
                    height={12}
                    width={12}
                    pathColor="#98A1A9"
                  />
                )}
              </div>
            </div>
          )}

          {/* <LogoutButton organization={props.organization} /> */}
          <SigninButton
            organization={props.organization}
            show={!disableAccounts()}
          />

          <Footer organization={props.organization} showSupportChat={true} />
        </div>
      );
    } else {
      return <Loading />;
    }
  };

  if (
    paymentIntent?.id &&
    paymentIntent.status !== 'requires_capture' &&
    paymentIntent.status !== 'succeeded'
  ) {
    return (
      <>
        <Header
          organization={props.organization}
          user={props.user}
          auth0User={props.auth0User}
        />
        <div
          style={{
            display: 'flex',
            flex: 1,
            height: '100vh',
            backgroundColor: '#F6F6F6',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '12px 40px',
          }}
        >
          <Text
            id="cancel-charging-text"
            size={20}
            style={{ textAlign: 'center' }}
          >
            Charging session terminated.
          </Text>
          <Text
            weight={400}
            size={14}
            color="#98A1A9"
            style={{ textAlign: 'center' }}
          >
            Pre-auth payment has been released and a confirmation has been sent
            to you via email.
          </Text>
          <br />
          <br />
          <Button
            id="go-back"
            className="action-button"
            style={{
              color: 'white',
              backgroundColor: organizationColor
                ? organizationColor
                : PRIMARY_COLOR,
              borderColor: organizationColor
                ? organizationColor
                : PRIMARY_COLOR,
              opacity: startCommandLoading || stopCommandLoading ? 0.4 : 1,
            }}
            onClick={() => {
              history.replace(`/${chargestation.static_endpoint}`);
            }}
          >
            Go Back
          </Button>
        </div>
        <Footer organization={props.organization} showSupportChat={true} />
      </>
    );
  } else if (paymentIntentId && connectorId) {
    return renderSession();
  } else {
    return <Loading />;
  }
}

const mapStateToProps = (state) => ({
  organization: state.organization,
  user: state.user,
  auth0User: state.auth0User,
});

export default connect(mapStateToProps)(Session);
