import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IoPersonCircleOutline } from 'react-icons/io5';
import { useAuth0 } from '@auth0/auth0-react';

import { DEFAULT_LOGO } from './../../constants';
import {
  disableAccounts,
  isDevelopment,
  disableWallets,
  isTestMode,
} from './../../config';
import {
  LogoutButton,
  SigninButton,
  SignupButton,
  DevelopmentWarningBanner,
  TestModeBanner,
} from 'components';
import Text from './../Text/Text';
import CloseOutlineIcon from './../Icon/CloseOutline';
import BackArrowIcon from './../Icon/BackArrow';
import './header.css';

const ListItem = ({ show, text, selected, onClick, id }) => {
  if (!show) {
    return null;
  }

  return (
    <div
      id={id}
      className="navigation-link-container"
      onClick={onClick}
      style={{
        backgroundColor: selected ? 'white' : null,
      }}
    >
      <Text size={16} weight={600}>
        {text}
      </Text>
    </div>
  );
};

const Header = ({
  organization,
  user,
  auth0User,
  showLogo = true,
  title = '',
  backgroundColor = 'white',
}) => {
  const { isAuthenticated } = useAuth0();
  const history = useHistory();
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <DevelopmentWarningBanner show={isDevelopment()} />
      <TestModeBanner show={isTestMode(organization.configurations)} />
      <div
        id="header"
        style={{
          display: 'flex',
          minHeight: 48,
          flexDirection: 'column',
          backgroundColor,
        }}
      >
        <div className="navigation-bar-container">
          {location.pathname !== '/' && (
            <div style={{ marginRight: 16, cursor: 'pointer' }}>
              <BackArrowIcon
                height={12}
                width={12}
                pathColor="#98A1A9"
                onClick={() => {
                  history.goBack();
                }}
              />
            </div>
          )}
          <div className="navigation-bar-details">
            {showLogo ? (
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => history.push('/')}
              >
                <img className="logo" src={organization?.logo} alt="" />
              </div>
            ) : null}

            {title ? (
              <div style={{ paddingLeft: 12, flex: 1, textAlign: 'center' }}>
                <Text size={16} weight={600}>
                  {title}
                </Text>
              </div>
            ) : null}

            <div style={{ cursor: 'pointer', visibility: 'hidden' }}>
              <img
                className="logo"
                src={organization?.logo ?? DEFAULT_LOGO}
                alt=""
              />
            </div>

            {!disableAccounts() && (
              <div
                onClick={() => {
                  setExpanded(!expanded);
                }}
              >
                {expanded ? (
                  <CloseOutlineIcon height={24} width={24} />
                ) : auth0User?.picture || user?.picture ? (
                  <img
                    id="profile-picture"
                    src={auth0User?.picture || user?.picture}
                    height={24}
                    width={24}
                    style={{ borderRadius: '50%' }}
                    alt=""
                  />
                ) : (
                  <IoPersonCircleOutline
                    id="profile-picture"
                    size="1.5em"
                    color="#98A1A9"
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <div className="navigation-expand-container">
          {expanded ? <br /> : ''}

          <ListItem
            id="header-favorites-button"
            show={expanded && isAuthenticated}
            onClick={() => history.push('/profile')}
            text="Profile Details"
            selected={location.pathname === '/profile'}
          />

          <ListItem
            id="header-wallet-button"
            show={expanded && isAuthenticated && !disableWallets()}
            onClick={() => history.push('/wallet')}
            text="Wallet"
            selected={location.pathname === '/wallet'}
          />

          <ListItem
            id="header-favorites-button"
            show={expanded && isAuthenticated}
            onClick={() => history.push('/favorites')}
            text="Favorites"
            selected={location.pathname === '/favorites'}
          />

          <ListItem
            id="header-transaction-history-button"
            show={expanded && isAuthenticated}
            onClick={() => history.push('/transaction_history')}
            text="Transactions"
            selected={location.pathname === '/transaction_history'}
          />

          <ListItem
            id="header-payment-methods-button"
            show={expanded && isAuthenticated}
            onClick={() => history.push('/payment_methods')}
            text="Payment Methods"
            selected={location.pathname === '/payment_methods'}
          />

          {expanded && (
            <LogoutButton
              organization={organization}
              style={{ backgroundColor: 'transparent', padding: 8 }}
            />
          )}
          {expanded && (
            <SigninButton
              organization={organization}
              style={{ backgroundColor: 'transparent', padding: 8 }}
            />
          )}
          {expanded && (
            <SignupButton
              organization={organization}
              style={{ backgroundColor: 'transparent', padding: 8 }}
            />
          )}
          {expanded ? <br /> : ''}
        </div>
      </div>
    </>
  );
};

export default Header;
